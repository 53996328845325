import { Helmet, HelmetProvider } from "react-helmet-async";

export default function DevelopmentSupport() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SIGQ - 士業特化の受託開発</title>
        <meta
          name="description"
          content="士業特化の受託開発についての情報を掲載しています。"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] m-auto mt-10">
        <div className="mt-10 mb-20">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900 text-center">
              士業特化の受託開発
            </h1>
          </div>
        </div>
        <div className="sm:flex sm:items-center">
          <div>
            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                士業システム開発の課題
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-[6fr_4fr] gap-10">
                <div>
                  <p>
                    士業に特化したシステム開発では、エンジニアにも法規制の深い理解が求められます。
                    例えば、システムの設計が<strong>非弁行為</strong>
                    に該当しないよう配慮する必要があります。
                  </p>
                  <p className="mt-2">
                    実際にそのシステムが法的に適切な処理を行なっているか否かの判断は専門家である弁護士が行う必要がありますが、全てが完成した後に「法的に不適切なのでリリースができない」となってしまうと、
                    <strong>大幅に手戻りの時間やコストが発生します。</strong>
                  </p>
                  <p className="mt-2">
                    そのようなケースの場合には、システムを開発・設計するエンジニアが法学の基礎的な知識を持ち合わせている場合には、「この機能はもしかしたら非弁行為に該当してしまうかもしれない」と設計段階で気づき、早めに専門家に相談を促すことで、方向転換をすることができます。
                  </p>
                </div>
                <div>
                  <p>例：近年のAI関連の非弁行為にまつわるガイドライン</p>
                  <p className="text-gray-600 mt-5">
                    <a
                      href="https://www.moj.go.jp/content/001400675.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-900"
                    >
                      ＡＩ等を用いた契約書等関連業務支援サービスの提供と弁護士法第
                      72 条との関係について
                    </a>
                  </p>
                </div>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                士業業務への深い理解
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-[6fr_4fr] gap-10">
                <div>
                  <p className="mt-2">
                    士業システムを開発するためには、士業の先生ほどの深い専門知識と経験は必ずしも必要はないですが、
                    学部レベルの法学の基礎的な考え方や士業の業務内容についての理解が必要です。
                    これが不足していると、プロダクトの設計段階で齟齬が生じ、意図しないシステムが出来上がるリスクがあります。
                  </p>
                  <p className="mt-2">
                    また、仕様に関連して六法を読んだり、過去の判例を読んだりする必要がありますが、法学を履修している開発者の場合には、よりスムーズに、士業の先生と目線を合わせて調査や仕様の議論を行うことができます。
                  </p>
                  <p className="mt-2">
                    さらに、士業事務所での業務では、裁判所や行政関連の手続きなどでFAXや紙でのやり取りが多く、ITのツールに馴染みのない方も多くいらっしゃると思います。
                    共同でプロジェクトを遂行する際に、普段様々なITのツールに慣れ親しんでいるIT企業の視点では見落としがちな使いにくさや、管理コストなどが発生することがありますので、予めよくいただく質問などをもとに先回りでご説明を行なったり、また、使用するツールをなるべく最小に絞り、シンプルな状態でプロジェクトを遂行いたします。
                  </p>
                </div>
                <div>
                  <img src="/lawyer.png" alt="" />
                </div>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                弊社の強み
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-[6fr_4fr] gap-10">
                <div>
                  <p>
                    弊社では、熊本大学で機械システム工学と法学を履修し、筑波大学大学院で情報工学を専攻している代表が、国内外のメガベンチャーで
                    <strong>
                      金融系サービスや堅牢性の求められる大規模Webサービスの開発を牽引
                    </strong>
                    してきたという変わった経験を活かし、士業のお客様に寄り添った最適なシステムを提案します。
                  </p>
                  <p className="mt-2">
                    手前味噌で大変恐縮ではございますが、法学と情報工学の知識を共に持ち、大規模サービス開発経験のあるエンジニアは人材マーケット全体を俯瞰しても、極めて希少です。
                    開発者の視点だけではなく、経営者の視点からの投資対効果なども考え、士業の先生方と協業して、他社よりも優れたシステムを構築する自信があります。
                  </p>
                </div>
                <div>
                  <img src="/programmer.png" alt="" />
                </div>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                お問い合わせ先
              </h2>
              <div>
                <p>
                  「作りたいものはあるけど、ふわっとしている」「まずは壁打ちをしてほしい」
                </p>
                <p>
                  どのようなお問い合わせでも構いません。まずはぜひ以下へご連絡ください。
                </p>
                <p className="mt-5">
                  電話番号：<strong>092-687-7741</strong>（平日9:00-18:00）
                </p>
                <p className="mt-2">メールアドレス：support@sigq.co.jp</p>
                <p className="mt-2">
                  <a
                    href="https://support.sigq.jp/hc/ja/requests/new"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    お問い合わせフォーム
                  </a>
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
