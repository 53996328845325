export function Footer() {
  const footer = [
    { name: "お知らせ", href: "/announce" },
    { name: "採用情報", href: "/recruit" },
    {
      name: "情報セキュリティ基本方針",
      href: "/security",
    },
    { name: "プライバシーポリシー", href: "https://sigq.jp/privacy-policy" },
    { name: "システム障害情報", href: "https://sigq.statuspage.io/" },
  ];

  return (
    <footer className="bg-gray-900">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-10 lg:px-8">
        <nav
          aria-label="Footer"
          className="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6"
        >
          {footer.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-white"
              target="_blank"
              rel="noreferrer"
            >
              {item.name}
            </a>
          ))}
        </nav>

        <p className="mt-10 text-center text-sm/6 text-gray-400">
          &copy; 2024 SIGQ, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
