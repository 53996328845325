import { Helmet, HelmetProvider } from "react-helmet-async";
import Markdown from "react-markdown";

const markdown = `
# 本社移転および株式会社化のお知らせ

平素より格別のご愛顧を賜り、厚く御礼申し上げます。

このたび、弊社は業務拡大と組織強化を図るため、**株式会社化**する運びとなりました。併せて、下記の通り本社を新たな所在地へ移転いたしますことをお知らせいたします。
今後も社員一同、より一層の努力をもって皆様のご期待にお応えしてまいりますので、引き続きご支援を賜りますようお願い申し上げます。

---
#### 新商号

株式会社SIGQ（SIGQ Co., Ltd.）

#### 新住所

〒812-0011
福岡県福岡市博多区博多駅前二丁目17-1 博多プレステージ本館 1F

#### 移転日および株式会社化日

2024年12月末頃（予定）

---

ご不明な点やお問い合わせがございましたら、以下の連絡先までご連絡ください。

#### お問い合わせ先

メールアドレス：support@sigq.co.jp

今後とも変わらぬご愛顧を賜りますよう、よろしくお願い申し上げます。
`;

export default function Announce20241115() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SIGQ - お知らせ</title>
        <meta name="description" content="本社移転および株式会社化のお知らせ" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] xl:w-[40%] m-auto mt-20 mb-20">
        <Markdown className="prose">{markdown}</Markdown>
      </div>
    </HelmetProvider>
  );
}
