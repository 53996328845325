import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SystemArchitect() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SIGQ - システムアーキテクト</title>
        <meta
          name="description"
          content="多岐にわたる技術スタックを活用し、様々な規模のサービスの設計、レビュー、運用を行ってきた実績があります"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] m-auto mt-10">
        <div className="mt-10 mb-20">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900 text-center">
              システムアーキテクト
            </h1>
          </div>
        </div>
        <div className="sm:flex sm:items-center">
          <div>
            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                弊社の強み
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-[6fr_4fr] gap-10">
                <div>
                  <p>
                    代表の金築は、シード期の企業から上場企業まで様々なフェーズの企業で、多岐にわたる技術スタックを活用し、様々な規模のサービスの設計、レビュー、運用を行ってきた実績があります。
                    対応可能なクラウド技術は、AWS、GCP、Cloudflare、Fastlyといった主要サービスに限らず、オンプレミスに至るまで
                    <strong>
                      お客様のシステムに最適なソリューションを提案
                    </strong>
                    することが可能です。
                  </p>
                  <p className="mt-2">
                    プログラミング言語についても、Go、TypeScript、JavaScript、Rubyといった多言語に対応可能であり、
                    フロントエンドではReactやVue.jsをはじめとする主要なフレームワークやツールの利用にも精通しています。
                    これにより、フルスタックでの開発が求められるプロジェクトでもスムーズに対応することが可能です。
                  </p>
                  <p className="mt-2">
                    また、
                    <strong>監視・運用ツール</strong>
                    に関しても豊富な経験を有しており、DatadogやGrafanaなどを駆使して、
                    <strong>
                      どのように効率的かつ効果的にシステムを監視・運用するか
                    </strong>
                    についてのノウハウも豊富です。
                    システムの規模や運用体制に応じた最適なモニタリング体制を構築し、
                    <strong>高い可用性と信頼性を実現</strong>します。
                  </p>
                  <p className="mt-2">
                    各種ベンダーの代理店ではないので、特定のツールを販売することはできませんが、逆に、特定のツールや技術に限定せず、幅広く最適なソリューションを提案します。
                  </p>
                </div>
                <div>
                  <img src="/programmer.png" alt="" />
                </div>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                特に得意な技術
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-[6fr_4fr] gap-10">
                <div>
                  <p className="mt-2">
                    <strong>・データベースの設計やデータ活用：</strong>
                    MySQLやPostgreSQLなどのRDBMSに加え、MongoDBやDocumentDBといったドキュメント型DB、RedisをはじめとするKVSの設計および運用経験、さらに近年注目されているインメモリDBやHTAPについても、学術論文を基盤とした体系的な知識を有し、豊富な設計や設計レビューの実績があります。また、論文を読むだけでなく、データベース関連の国際学会に向けた英語論文の執筆にも積極的に取り組んでいます。
                  </p>
                  <p className="mt-2">
                    <strong>・分散システム：</strong>
                    複数のシステムを異なるサーバーで動かす分散システムでは、トランザクションをまたぐデータの整合性を担保する方法や、補償トランザクションによる結果整合性の確保といったデータ関連の課題だけでなく、アプリケーション間の
                    ingress や egress
                    通信を監視し、意図しないリクエストが発生していないかを確認する必要があるケースも存在します。これらはいずれも技術的に高度な対応が求められる領域です。そこで、そもそもお客様の環境において分散システムが本当に必要かどうかを一緒に検討し、最適なアーキテクチャをご提案します。
                  </p>
                  <p className="mt-2">
                    <strong>
                      ・クラウドインフラを使用した堅牢かつ信頼性の高いシステム設計：
                    </strong>
                    パフォーマンス、安定性、信頼性のトレードオフを考慮し、99.99%といった高いSLAで稼働するサービスの設計をサポートします。これには、堅牢なアーキテクチャの構築やSLO・SLAの策定に関する観点も含めて対応します。
                  </p>
                </div>
                <div>
                  <img src="/server.png" alt="" />
                </div>
              </div>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-bold text-blue-900 mb-4">
                お問い合わせ先
              </h2>
              <div>
                <p>
                  どのようなお問い合わせでも構いません。まずはぜひ以下へご連絡ください。
                </p>
                <p className="mt-5">
                  電話番号：<strong>092-687-7741</strong>（平日9:00-18:00）
                </p>
                <p className="mt-2">メールアドレス：support@sigq.co.jp</p>
                <p className="mt-2">
                  <a
                    href="https://support.sigq.jp/hc/ja/requests/new"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    お問い合わせフォーム
                  </a>
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
