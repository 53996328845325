import { Helmet, HelmetProvider } from "react-helmet-async";

export const mediaAuthors = {
  brown: {
    name: "Takaaki Kanetsuki",
    role: "CEO",
  },
};

export const mediaCategories = {
  security: "セキュリティ",
};

export const mediaPostList = [
  {
    id: 1,
    title: "セキュリティ対策の基礎",
    href: "/media/begin-security-operation",
    description:
      "サイバー攻撃のリスクは中小企業にも！データ漏洩や業務停止を防ぐために、どういうリスクがあるのかや、まずは何をやっていく必要があるのかをわかりやすくご紹介しています。",
    subTitle:
      "サイバー攻撃のリスクは中小企業にも！データ漏洩や業務停止を防ぐために、どういうリスクがあるのかや、まずは何をやっていく必要があるのかをわかりやすくご紹介しています。",
    date: "2024-12-06",
    datetime: "2024-12-06",
    category: { title: mediaCategories.security },
    author: mediaAuthors.brown,
    eyeCatchPhoto: {
      href: "/media/eyecatch-security1.jpeg",
      alt: "",
    },
  },
];

export default function Media() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>情報漏洩対策メディア</title>
        <meta
          name="description"
          content="情報漏洩対策や、セキュリティ対策に役立つ情報を発信しています。"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              情報漏洩対策メディア
            </h2>
            <p className="mt-2 text-lg/8 text-gray-600">
              手軽に始められて、効果の高い情報漏洩対策を発信しています。
            </p>
          </div>

          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {mediaPostList.map((post) => (
              <article
                key={post.id}
                className="flex flex-col items-start justify-between"
              >
                <div className="relative w-full">
                  <img
                    alt={post.eyeCatchPhoto.alt}
                    src={post.eyeCatchPhoto.href}
                    className="aspect-video w-full rounded-2xl bg-gray-100 object-cover"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 ">
                      {post.category.title}
                    </span>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm/6 text-gray-600">
                      {post.subTitle}
                    </p>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <div className="text-sm/6">
                      <p className="font-semibold text-gray-900">
                        {post.author.name}
                      </p>
                      <p className="text-gray-600">{post.author.role}</p>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>

          <div className="flex flex-col md:flex-row mt-10">
            <div className="w-full md:w-[15%]"></div>
            <div className="w-full md:w-[30%] ">
              <a href="https://sigq.jp" target="_blank" rel="noreferrer">
                <img src="/media/banner202412.png" alt="無料プラン" />
              </a>
            </div>
            <div className="w-full md:w-[10%]"></div>
            <div className="w-full md:w-[30%]">
              <a
                href="https://note.com/sigq/n/nd241098892db"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/media/free_campaign.png"
                  alt="キャンペーン"
                  className="mt-10"
                />
              </a>
            </div>
            <div className="w-full md:w-[15%]"></div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
