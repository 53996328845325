import { Helmet, HelmetProvider } from "react-helmet-async";
import Markdown from "react-markdown";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";

const pages = [
  { name: "投稿一覧", href: "/media", current: false },
  { name: "現在のページ", href: "", current: true },
];

export default function MediaPageLayout({
  description,
  eyeCatchPhoto,
  category,
  title,
  author,
  subTitle,
  postDate,
  body,
}: {
  description: string;
  eyeCatchPhoto: {
    href: string;
    alt: string;
  };
  author: {
    name: string;
    role: string;
  };
  category: string;
  title: string;
  subTitle: string;
  postDate: string;
  body: string;
}) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>情報漏洩対策メディア - SIGQ</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="bg-gray-100 min-h-screen">
        <div className="px-4 sm:px-6 lg:px-8 w-full xl:w-[90%] m-auto mt-20">
          <nav aria-label="Breadcrumb" className="flex mb-10">
            <ol className="flex items-center space-x-4 mt-10">
              <li>
                <div>
                  <a href="/" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon aria-hidden="true" className="size-5 shrink-0" />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      aria-hidden="true"
                      className="size-5 shrink-0 text-gray-400"
                    />
                    <a
                      href={page.href}
                      aria-current={page.current ? "page" : undefined}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>

          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-[70%] bg-white p-5 mb-20">
              <div className="divide-y divide-gray-200 overflow-hidden m-auto">
                <div className="py-5">
                  <img
                    src={eyeCatchPhoto.href}
                    alt={eyeCatchPhoto.alt}
                    className="object-cover"
                  ></img>
                </div>
                <div className="py-4">
                  <h1 className="text-2xl font-semibold">{title}</h1>
                  <h2 className="text-lg text-gray-500 mt-2">{subTitle}</h2>
                  <p className="text-gray-500 mt-2">
                    {" "}
                    {author.role} / {author.name}
                  </p>
                  <p className="text-gray-500 mt-2 text-sm">
                    <div className="flex items-center gap-x-4 text-xs">
                      <time dateTime={postDate} className="text-gray-500">
                        {postDate}
                      </time>
                      <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 ">
                        {category}
                      </span>
                    </div>
                  </p>
                </div>
              </div>
              <hr className="my-5" />
              <div className="divide-y divide-gray-200">
                <Markdown className="prose w-full">{body}</Markdown>
              </div>
            </div>

            <div className="hidden md:block md:w-[5%]"></div>
            <div className="w-full md:w-[20%]">
              <div className="w-full">
                <a href="https://sigq.jp" target="_blank" rel="noreferrer">
                  <img src="/media/banner202412.png" alt="無料プラン" />
                </a>

                <a
                  href="https://note.com/sigq/n/nd241098892db"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/media/free_campaign.png"
                    alt="キャンペーン"
                    className="mt-10"
                  />
                </a>

                <a href="https://sigq.jp" target="_blank" rel="noreferrer">
                  <img
                    src="/media/banner_202412_long.png"
                    alt="Cloud Linker"
                    className="mt-10"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
