import { mediaPostList } from "../Media";
import MediaPageLayout from "./MediaPageLayout";

// ![sample](/media/banner202412.png "サンプル")
const markdown = `
昨今のサイバー攻撃は、決して大企業だけの問題ではありません。実は中小企業も攻撃のターゲットとなることが増えており、適切な対策を取らないと、ビジネスに深刻な影響を及ぼす可能性があります。データ漏洩、業務停止、顧客からの信頼喪失など、サイバー攻撃の影響は広範囲に及びます。では、どのように対策を進めていけばいいのでしょうか？

　この記事では、中小企業の経営者やマネジメント層の方々に向けて、サイバー攻撃のリスクと、まず取り組むべき基本的なセキュリティ対策について分かりやすくご紹介します。

## サイバー攻撃のリスク：中小企業が狙われる理由
「自社は規模が小さいから狙われない」と思っていませんか？  
実は、サイバー攻撃者にとって中小企業は**格好のターゲット**です。その理由は以下の通りです。

#### セキュリティ対策の不十分さ
- 大企業に比べてセキュリティ対策に投資する余裕がない場合が多く、攻撃者にとって侵入しやすい。
  - セキュリティ対策は目にみえる費用をかけて行うことだけではなく、日々の些細なことの積み重ねがセキュリティ対策となっていく。
  - 勉強会や本を読むだけでセキュリティ対策の「眼」を養うことは難しく、セキュリティに詳しいメンバーが不在の場合には、そもそも「**どういう状態が問題があるのか**」「**どういう状態がセキュリティが高いと言えるのか**」ということがわからない。

#### サプライチェーン攻撃の一環
- 中小企業は大企業の取引先であることが多く、そこから大企業への侵入を図られる。
  - サプライチェーン攻撃とは、**組織（サプライ）のつながり（チェーン）を辿って、その先にいる組織を攻撃すること**を指す。
  - 対象となる、大企業はセキュリティ対策をコストをかけてしっかりと行なっているので侵入しにくい。
  - そこで、その大企業に出入りしていたり、取引があって、かつセキュリティ対策が薄い中小企業をまず攻撃し、ターゲットとなる大企業とのチェーンを辿って、その大企業を攻撃すること。

  ![サプライチェーン攻撃](/media/posts/post1_1.png "サプライチェーン攻撃")

#### ランサムウェアの標的
- **セキュリティ投資におけるリソース格差**
  - 中小企業ではセキュリティ投資が優先順位の低い項目とされがちで、結果として脆弱性を抱えたまま業務を進めることが多い。
- **被害公開のリスク意識の違い**
  - 大企業はコンプライアンスや規制上の要件から、ランサムウェアなどのサイバー攻撃を受けた場合に情報公開が求められる。
  - 身代金を支払うことは、株主や規制当局からの厳しい監視を受けるリスクがあるため慎重な判断を迫られる。
  - 一方、中小企業は情報公開の義務がない場合が多く、また、オーナー社長の判断で口座のお金を動かしやすいため、外部への影響を最小限に抑えるために身代金を支払ってしまいやすい。
- **業務停止の耐久力**
  - 大企業は業務が一部停止しても、多くの場合、冗長化されたシステムや他拠点のリソースを活用して事業を継続する能力を持っていることが多い。
  - しかし、中小企業では業務が完全に停止することが多く、迅速な復旧が求められるため、攻撃者の要求に従うしかない場合が多い。
- **攻撃者のターゲティング戦略**
  - 攻撃者はゲーム理論的に、大企業と中小企業のリスク耐性を理解している。
  - 大企業には高額な身代金を要求する一方、中小企業には「手の届く範囲」の金額を提示することが多い。
  - 中小企業は相対的に支払いやすいと判断されるため、攻撃者から狙われやすい傾向がある。
- **社内対応力の差**
  - 大企業はサイバー攻撃に対処するためのセキュリティ専門チームや外部ベンダーとの契約を結んでおり、身代金を支払わなくても復旧できる可能性が高い。
  - 一方、中小企業はこうした専門知識やリソースが不足しているため、攻撃に対処する手段が限られ、要求に応じてしまうことがある。

## まず何をするべき？初心者向けセキュリティ対策
「何から手を付けたらいいのかわからない」という方のために、すぐに始められる基本的な対策を以下にまとめました。

### 1. セキュリティ意識の向上
単にセキュリティツールを導入したり、規則を厳しくするだけでは意味が薄く、役員や社員などの役職を問わず、全員のセキュリティ意識を底上げしていく必要があります。  
　「カフェで仕事をしてはならない」という規則を設けている会社は多いのですが、本当にそれだけで情報が守れるのでしょうか？例えば、「カフェで仕事をしてはならない」という規則を守っていても、不特定多数のいる飲食店での飲み会の場で業務の話をするのはよいのでしょうか。  

　以下は、一般的にはあまりルールになってはいないものの、セキュリティを向上するためには必ず意識するべきことの例です。一見些細に見えますが、これらは案外意識ができている方が少ないです。
- **スマホ・PCにのぞき見防止シートを使う**
  - キーワード：「プライバシーシート」「覗き見防止シート」
  - 満員電車の中で普通にスマホで何かを閲覧していると、その画面は周りの人はみようと思っていなくても画面が丸見え。
  - 数百円程度で購入できるのぞき見防止シートを貼るだけで、横からは意図的に覗き込まないと画面が見えなくなる。
- **離席時にパソコンを閉じる**
  - 会社であっても、離席時にはパソコンを閉じないと、他の人でも自由に操作可能。
  - 悪意のある攻撃者がオフィスの中に侵入している場合には、ロックのかかっていないパソコンは格好のセキュリティホールになる。
- **会社の外で機密性の高い情報を含んだ話をしない**
  - 飲食店やオフィス付近のカフェでは、機密性の高い情報を同僚と話している声がしばしば聞こえることがある。
  - 個人情報や会社の戦略などが外部に流れるだけではなく、上場企業の情報の場合にはインサイダー取引にもなりかねない。

### 2. 2段階認証を使用する
- 二段階認証を導入することで、アカウント乗っ取りリスクを軽減する。
- パスワードを紙に書いたり、使いまわさらない。パスワードマネージャーを使用する。

### 3. ソフトウェアのアップデート
- 使用しているソフトウェアやシステムを最新の状態に保つ。
- アップデートを怠ると、既知の脆弱性を突かれる可能性が高まる。

### 4. 出どころがわからないアプリケーションなどを使用しない
- 無料で使えるからという理由で、会員登録も必要ないサービス経由でファイルを共有するケースが散見される。
- 一度流出した情報は撤回することができないため、無料だけではなく、有料のサービスであっても、セキュリティ的に問題がないかを判断してから使用する。

### まとめ
上記はあくまでも一般論としてのお作法を記載しており、実際には個社ごとに現状の業務の洗い出しや、関係者を俯瞰した長期的かつ、包括的なセキュリティの対策が必要になります。

　高額なコンサルティング費用をかけて、数年に一度セキュリティ対策をすることは十分な対策とは言えず、むしろやっていないに等しい場合が往々にしてあります。  
　そこSIGQでは、「**ITに詳しくて、連絡をしたら柔軟に相談に応じてくれる**」をテーマに、皆様の課題のヒアリングから対策までサポートすることも行なっているので、お気軽にご連絡ください。  

**メールアドレス: support@sigq.co.jp**  
**電話番号: 092-687-7741**
`;

export default function BeginSecurityOperation20241206() {
  return (
    <MediaPageLayout
      description={mediaPostList.find((m) => m.id === 1)!.description}
      eyeCatchPhoto={{
        href: mediaPostList.find((m) => m.id === 1)!.eyeCatchPhoto.href,
        alt: mediaPostList.find((m) => m.id === 1)!.eyeCatchPhoto.alt,
      }}
      author={mediaPostList.find((m) => m.id === 1)!.author}
      category={mediaPostList.find((m) => m.id === 1)!.category.title}
      title={mediaPostList.find((m) => m.id === 1)!.title}
      subTitle={mediaPostList.find((m) => m.id === 1)!.subTitle}
      postDate={mediaPostList.find((m) => m.id === 1)!.datetime}
      body={markdown}
    />
  );
}
