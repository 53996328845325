import { Helmet, HelmetProvider } from "react-helmet-async";
import Markdown from "react-markdown";

const markdown = `
# 学生インターンシップ・アルバイト

## 仕事概要

#### 業務内容の一例
SIGQ Cloud Linkerの管理画面開発

サポートサイト等のドキュメント整備

#### 本ポジションの魅力
- 他社の上場企業やメガベンチャーと言われるような会社で国内外問わず、幅広く経験を積んできたエンジニアがメンターになります。そのため、実践の場で本当に生きるスキルを働きながら身につけることができます。
  - 弊社は現在まだ正社員の採用は行えていないため、本ポジションから正社員になることはありませんが、やる気次第で、大手他社でも十分に通用するスキルを身につけることができます。
- 土日でも稼働可能です（※土日、祝日の割増賃金はありません）

#### 開発環境
フロントエンド: React

バックエンド: Go

データベース: MongoDB

インフラ: GCP, Cloudflare

コミュニケーション: Slack, GitHub

監視ツール: DataDog

#### 必須要件
- 熊本・茨城県内の大学生・大学院生で、情報工学もしくは関連した分野（数学・物理など）を専攻していること（実務でのプログラミング経験は問わない）
- 週10h程度の稼働が可能であること
- 非同期コミュニケーションスキル
  - リモート環境下での非同期コミュニケーションを通じて明確かつ的確に要件やアイディアを伝える能力

#### 歓迎条件
- クラウドベースのSaaS開発経験
- セキュリティやデータ保護に関する知識
- 他社でのインターン経験

#### 勤務形態
- 基本的にはフルリモート勤務ですが、必要に応じて対面での会議を行う場合があります
- 土日平日問わず、稼働日時は柔軟に相談の上決定
- 時給1500円〜

#### 選考フロー
- 書類選考
- 一次選考
  - 回数は増減する場合もあります

#### その他
- 本募集ポジションは学生インターンシップ・アルバイトの雇用契約ですが、候補者様からの希望により業務委託契約も相談可能です

---

ご応募や、お問い合わせがございましたら、以下の連絡先までご連絡ください。

ご応募の際には、まずはカジュアル面談から行います。お名前と所属、専攻や、ご興味いただいた点に関してメールに簡単に添えてお送りください。カジュアル面談の段階では履歴書は不要です。

#### お問い合わせ先

メールアドレス：recruit@sigq.co.jp
`;

export default function Intern202411() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SIGQ - 採用情報</title>
        <meta name="description" content="学生インターンシップ・アルバイト" />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] xl:w-[40%] m-auto mt-20 mb-20">
        <Markdown className="prose">{markdown}</Markdown>
      </div>
    </HelmetProvider>
  );
}
