import { lazy, Suspense } from "react";

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Announce from "./pages/Announce";
import Announce20241115 from "./pages/accounces/post1";
import Recruit from "./pages/Recruit";
import Intern202411 from "./pages/recruits/202411-intern";
import DevelopmentSupport from "./pages/DevelopmentSupport";
import SystemArchitect from "./pages/SysntemArchitect";
import Security from "./pages/Security";
import Media from "./pages/Media";
import BeginSecurityOperation20241206 from "./pages/media/post1";

const LP = lazy(() => import("./pages/LP"));
const PublicNotice = lazy(() => import("./pages/PublicNotice"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="" element={<LP />} />
            <Route path="/public-notice" element={<PublicNotice />} />

            <Route path="/announce" element={<Announce />} />
            <Route path="/announce/20241115" element={<Announce20241115 />} />

            <Route path="/recruit" element={<Recruit />} />
            <Route path="/recruit/202411-intern" element={<Intern202411 />} />

            <Route path="/security" element={<Security />} />

            <Route
              path="/development-support"
              element={<DevelopmentSupport />}
            />
            <Route path="/system-architect" element={<SystemArchitect />} />

            <Route path="/media" element={<Media />} />
            <Route
              path="/media/begin-security-operation"
              element={<BeginSecurityOperation20241206 />}
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
